<template>
  <div class="bordered-box">
    <h3 class="ml-3">Add Variations</h3>
    <v-divider class="my-4"></v-divider>
    <variation-item
      v-for="(item, index) in items"
      :key="index"
      :colors="colors"
      :sizes="sizes"
      :item="item"
      :cakes="cakes"
      :pid="pid"
      :variationson="variationson"
      :isLastItem="showOrHide(item)"
      @addOneMore="addOneMore"
      @multiVariationAdded="multiVariationAdded"
    ></variation-item>
    <!-- <stepper-buttons
      @updateStep="updateStep"
      @backStep="backStep"
    ></stepper-buttons> -->
  </div>
</template>
<script>
// import { mapState } from "vuex";
// import StepperButtons from "../common/StepperButtons.vue";
import VariationItem from "./VariationItem.vue";
import filterCategoryMixin from "../common/filterCategoryMixin";
export default {
  mixins: [filterCategoryMixin],
  components: { VariationItem },
  data() {
    return {
      items: 1,
      addBtnOnEdit: false,
    };
  },
  props: {
    colors: {
      type: Array,
      default: () => [],
    },
    sizes: {
      type: Array,
      default: () => [],
    },
    variationson: {
      type: Array,
      default: () => [],
    },
    variations: {
      type: Array,
      default: () => [],
    },
    pid: {
      type: String,
      default: () => null,
    },
    cakes: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    addOneMore() {
      if (this.variations.length) {
        this.items.push(this.variationson);
      } else {
        this.items += 1;
      }
    },
    showOrHide(val) {
      if (this.variations.length) {
        const last = val === this.items[this.items.length - 1];
        this.addBtnOnEdit = last;
        return last;
      } else {
        return val === this.items;
      }
    },
    updateStep() {
      this.$emit("updateStep");
    },
    backStep() {
      this.$emit("backStep");
    },
    multiVariationAdded(val){
      this.$emit('multiVariationAdded', val)
    }
  },
  created() {
    if (this.variations) {
      if (this.variations.length > 0) {
        // console.log(data.variations);
        this.items = this.variations;
      }
    }
  },

};
</script>
