<template>
  <div class="bordered-box">
    <h3 class="ml-3">{{ product ? "Edit" : "" }} Basic Details</h3>
    <small>* indicates required fields</small>
    <v-divider class="my-4"></v-divider>
    <v-form ref="step_form">
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-text-field
            v-model="name"
            outlined
            dense
            label="Enter Product Title *"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="12">
          <v-autocomplete
            v-model="cat_id"
            :items="categories"
            item-text="name"
            item-value="id"
            outlined
            dense
            label="Choose Category *"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="12">
          <v-autocomplete
            v-model="sub_cat"
            :items="filterSubCat"
            item-text="name"
            item-value="id"
            outlined
            dense
            label="Choose Sub Category *"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="12">
          <v-autocomplete
            v-model="sub_sub_cat"
            :items="filterSubSubCat"
            item-text="name"
            item-value="id"
            outlined
            dense
            label="Choose Sub Sub Category"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="12">
          <v-autocomplete
            v-model="brand_id"
            :items="brands"
            item-text="name"
            item-value="id"
            outlined
            dense
            label="Choose Brand"
          ></v-autocomplete>
        </v-col>
        <!-- <v-col cols="12" lg="4" md="6" sm="12">
          <v-text-field
            v-model="dimensions"
            outlined
            dense
            label="length x breadth x height"
          >
          </v-text-field>
        </v-col>-->
        <v-col cols="12" lg="4" md="6" sm="12">
          <v-text-field
            v-model="unit"
            outlined
            dense
            label="Unit (Ex: 1 Piece, 1 Kg) *"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="8" md="8" sm="8">
          <v-combobox
            v-model="tag"
            :items="tags"
            item-text="name"
            item-value="id"
            :search-input.sync="search"
            hide-selected
            deletable-chips
            outlined
            label="Add some tags"
            multiple
            persistent-hint
            small-chips
            dense
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>{{ search }}</strong
                    >". Press <kbd>enter</kbd> to create a new one
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-img
            v-if="product"
            :src="product.featuredImage"
            width="20%"
            class="mb-2"
          ></v-img>
          <v-file-input
            v-model="featured_image"
            label="Upload Featured Image *"
            prepend-icon=""
            outlined
            dense
            accept="image/*"
            @change="setImage($event, 'featured')"
            :rules="product && product.featuredImage ? [] : [rules.required]"
          ></v-file-input>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <div v-if="product && product.otherImages" class="mb-2">
            <div v-for="(img, index) in product.otherImages" :key="index">
              <v-img :src="img" width="20%" style="float: left"></v-img>
            </div>
          </div>
          <v-file-input
            v-model="images"
            label="Upload Multiple Image"
            prepend-icon=""
            outlined
            dense
            multiple
            accept="image/*"
            class="d-block"
            @change="setImage($event, 'images')"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-label
            >Description *
            <span v-if="descerr" style="color: red">{{
              descerr
            }}</span></v-label
          >

          <vue-editor
            v-model="description"
            :editorToolbar="customToolbar"
            required
            class="mt-2"
          ></vue-editor>
        </v-col>
        <!-- <v-col cols="12" lg="6" md="6" sm="12">
          <v-label>Highlights</v-label>
          <vue-editor
            v-model="highlights"
            :editorToolbar="customToolbar"
            class="mt-2"
          ></vue-editor>
        </v-col> -->
        <!-- <v-col cols="12" lg="6" md="6" sm="12">
          <v-label>Whats inside the box</v-label>
          <vue-editor
            v-model="in_the_box"
            :editorToolbar="customToolbar"
            class="mt-2"
          ></vue-editor>
        </v-col> -->
        <!-- <v-col cols="12" lg="6" md="6" sm="12">
          <v-label>Some Important Note (If Any)</v-label>
          <vue-editor
            v-model="note"
            :editorToolbar="customToolbar"
            class="mt-2"
          ></vue-editor>
        </v-col> -->
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-text-field
            v-model="warranty_summary"
            dense
            outlined
            label="Warranty Duration"
            hint="1 Year Brand Warranty"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!product" class="mb-6">
        <v-col cols="12" lg="12" md="12" sm="12">
          <h4>
            Does this product has variation on Size, Weight or Color?
            <span v-if="variationErr" style="color: red">{{
              variationErr
            }}</span>
          </h4>
          <p>
            <small
              >If this product has various sizes, color or weight and product
              price varies accordingly, Click YES if not Click NO</small
            >
          </p>
          <div class="d-flex pt-4">
            <v-btn
              small
              color="success"
              :disabled="variationAdded || multipleVariations"
              class="mr-5 px-12"
              @click="hasVariation"
              >Yes</v-btn
            >
            <v-btn
              small
              :disabled="variationAdded || multipleVariations"
              color="error"
              class="px-12"
              @click="noVariation"
              >No</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-dialog v-if="!product" v-model="dialog" persistent max-width="500px">
        <v-card>
          <v-card-title
            >Product Details
            <v-spacer></v-spacer>
            <v-btn
              class="primary-color p-0"
              max-width="40"
              @click="dialog = !dialog"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-divider class="mb-4"></v-divider>
          <v-card-text>
            <h5 class="mb-2">
              You have selected No and we request you to fill the form below
            </h5>
            <v-form ref="variationForm">
              <v-text-field
                v-model="price"
                outlined
                dense
                label="Unit Price *"
                hint="Price of 1 product"
                required
                :rules="[rules.required, rules.positiveInteger]"
                type="number"
              ></v-text-field>
              <v-text-field
                v-model="selling_price"
                outlined
                dense
                label="Discounted Selling Price *"
                hint="At What Price You Want To Sell This Product? If you donot want to add discount, leave it blank"
                type="number"
                required
                :rules="[rules.required, rules.positiveInteger]"
              ></v-text-field>
              <v-autocomplete
                v-model="color"
                :items="colors"
                item-text="name"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                label="Choose Product Colors"
              ></v-autocomplete>
              <v-autocomplete
                v-model="size"
                :items="sizes"
                item-text="code"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                label="Choose Size"
              ></v-autocomplete>
              <v-text-field
                v-model="weight"
                outlined
                dense
                label="Product Weight"
              ></v-text-field>
              <!-- <div class="pr-4">
                <v-text-field
                  v-model="type"
                  outlined
                  dense
                  label="Product Type"
                ></v-text-field>
              </div> -->
              <div class="pr-4">
                <v-text-field
                  v-model="stock"
                  outlined
                  dense
                  label="Stock or Total Quantity *"
                  required
                  :rules="[rules.required]"
                ></v-text-field>
              </div>
              <v-btn color="primary" class="px-6" @click="noVariationForm"
                >Submit</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-if="!product" v-model="dialog1" persistent max-width="500px">
        <v-card>
          <v-card-title
            >Product Price Details
            <v-spacer></v-spacer>
            <v-btn
              class="primary-color p-0"
              max-width="40"
              @click="dialog1 = !dialog1"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-divider class="mb-4"></v-divider>
          <v-card-text>
            <h5 class="mb-2">
              You have selected Yes and we request you to select filed that
              changes your product price.
            </h5>
            <div class="d-flex">
              <div class="pr-4">
                <v-checkbox
                  v-model="vcolor"
                  label="Colour"
                  color="red"
                  hide-details
                ></v-checkbox>
              </div>
              <div class="pr-4">
                <v-checkbox
                  v-model="vsize"
                  label="Size"
                  color="red"
                  hide-details
                ></v-checkbox>
              </div>
              <div class="pr-4">
                <v-checkbox
                  v-model="vweight"
                  label="Weight"
                  color="red"
                  hide-details
                ></v-checkbox>
              </div>
              <div class="pr-4">
                <v-checkbox
                  v-model="vtype"
                  label="Type"
                  color="red"
                  hide-details
                ></v-checkbox>
              </div>
            </div>
            <v-btn
              color="primary"
              class="px-6 mt-6"
              :loading="loading"
              @click="passPropsToVariation"
              >Submit</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>
    <v-row>
      <v-col cols="6" class="ml-auto">
        <v-btn
          color="primary-color"
          class="standard-border"
          block
          :disabled="saveDisabled"
          :loading="saveload"
          @click="updateStep"
        >
          Save and Continue
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from "vuex";

import { VueEditor } from "vue2-editor";
import filterCategoryMixin from "./common/filterCategoryMixin";

export default {
  mixins: [filterCategoryMixin],
  components: {
    VueEditor,
  },
  props: {
    colors: {
      type: Array,
      default: () => [],
    },
    sizes: {
      type: Array,
      default: () => [],
    },
    product: {
      type: Object,
      default: () => {},
    },
    editvariations: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      variationAdded: false,
      saveload: false,
      search: "",
      name: "",
      brand_id: "",
      in_the_box: "",
      note: "",
      cat_id: "",
      sub_cat: "",
      sub_sub_cat: "",
      vcolor: "",
      stock: "",
      unit: "Unit",
      vsize: "",
      color: "",
      dimensions: "",
      warranty: false,
      saveDisabled: false,
      variations: false,
      multipleVariations: false,
      variationErr: "",
      warranty_summary: "",
      size: "",
      price: "",
      selling_price: "",
      weight: "",
      vweight: "",
      vtype: "",
      type: "",
      tag: [],
      description: "",
      descerr: "",
      highlights: "",
      dialog: false,
      dialog1: false,
      featured_image: null,
      images: [],
      rules: {
        required: (v) => !!v || "This field is required.",
        positiveInteger: (v) => {
          if (v) return Number(v) > 0 || "Only positive number allowed";
          else return true;
        },
      },
      customToolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ header: [false, 1, 2, 3, 4] }],
      ],
    };
  },

  computed: {
    filterSubCat() {
      return this.filterCategories(this.subcategories, this.cat_id, "cat_id");
    },
    filterSubSubCat() {
      return this.filterCategories(
        this.subsubcategories,
        this.sub_cat,
        "cat_id"
      );
    },
    ...mapState({
      categories: (state) => state.categories,
      subcategories: (state) => state.subcategories,
      subsubcategories: (state) => state.subsubcategories,
      brands: (state) => state.brands,
      tags: (state) => state.tags,
      // uncompleteProduct: (state) => state.uncompleteProduct,
    }),
  },
  watch: {
    cat_id() {
      const category = this.filterCategories(
        this.categories,
        this.cat_id,
        "id",
        true
      );
      this.$emit("category", category);
    },
    sub_cat() {
      const subcategory = this.filterCategories(
        this.subcategories,
        this.sub_cat,
        "id",
        true
      );
      this.$emit("subcategory", subcategory);
    },
  },
  methods: {
    noVariationForm() {
      if (this.$refs.variationForm.validate()) {
        this.variationAdded = true;
        this.dialog = false;
      }
    },
    async updateStep() {
      if (this.$refs.step_form.validate()) {
        if (this.description) {
          if (this.variationAdded || JSON.stringify(this.product) !== "{}") {
            this.saveload = true;
            this.saveDisabled = true;
            await this.submitForm();
            if (this.multipleVariations) this.$emit("updateStep");
            else this.$emit("submitAll");
          } else {
            this.variationErr = "Required";
          }
        } else {
          this.descerr = "Required";
        }
      }
    },
    hasWarranty() {
      this.warranty = true;
      this.$emit("haswarranty");
    },
    noWarranty() {
      this.warranty = true;
      this.$emit("nowarranty");
    },
    noVariation() {
      this.$emit("novariation");
      this.dialog = !this.dialog;
    },
    hasVariation() {
      this.multipleVariations = true;
      this.$emit("hasvariation");
      this.dialog1 = !this.dialog1;
    },
    setImage(files, type) {
      if (type === "featured") {
        this.featured_image = files;
      } else {
        this.images = files;
      }
    },
    async submitForm() {
      let formdata = new FormData();
      if (this.product) formdata.append("id", this.product.id);
      formdata.append("name", this.name);
      formdata.append("brand_id", this.brand_id);
      formdata.append("tag", JSON.stringify(this.tag));
      formdata.append("cat_id", this.cat_id);
      formdata.append("sub_cat", this.sub_cat);
      formdata.append("sub_sub_cat", this.sub_sub_cat);
      formdata.append("dimensions", this.dimensions);
      formdata.append("unit", this.unit);
      formdata.append("color", this.color);
      formdata.append("size", this.size);
      formdata.append("stocks", this.stock);
      formdata.append("description", this.description);
      formdata.append("weight", this.weight);
      formdata.append("highlights", this.highlights);
      formdata.append("in_the_box", this.in_the_box);
      formdata.append("note", this.note);
      formdata.append("featured_image", this.featured_image);
      this.images.forEach((file, index) => {
        formdata.append("images[" + index + "]", file);
      });
      formdata.append("price", this.price);
      formdata.append("selling_price", this.selling_price);
      formdata.append("type", this.type);
      formdata.append("warranty_summary", this.warranty_summary);
      formdata.append("is", "basic_details");
      await this.$store
        .dispatch("addProduct", formdata)
        .then((res) => {
          this.$emit("pid", res);
          this.saveload = false;
        })
        .catch(() => {
          this.$swal(
            "Failed",
            "Something Went Wrong. Refresh and try again",
            "error"
          );
          this.saveload = false;
          this.saveDisabled = false;
        });
      return true;
    },
    passPropsToVariation() {
      this.loading = true;
      const data = {
        color: this.vcolor,
        size: this.vsize,
        weight: this.vweight,
        type: this.vtype,
      };
      this.dialog1 = false;
      this.$emit("variations", data);
      this.loading = false;
      // this.variationAdded = true;

      // if (await this.submitForm()) this.$emit("updateStep");
    },
  },
  created() {
    if (!this.brands.length) {
      this.$store.dispatch("getAllBrands");
    }
    if (!this.tags.length) {
      this.$store.dispatch("getAllTags");
    }
    const data = this.product;
    if (data) {
      const details = data.details;
      this.name = data.name;
      this.brand_id = data.brand ? data.brand.id : "";
      this.note = details.note;
      this.cat_id = data.category.id;
      if (data.subcategory) {
        this.sub_cat = data.subcategory.id;
      }
      if (data.subsubcategory) {
        this.sub_sub_cat = data.subsubcategory.id;
      }
      this.unit = details.unit;
      this.dimensions = details.dimensions;
      this.description = details.description;
      this.highlights = details.highlights;
      this.in_the_box = details.in_the_box;
      this.note = details.note;

      data.tags.forEach((tag) => {
        this.tag.push(this.tags.filter((item) => item.id === tag.id)[0]);
      });
      // this.featured_image = data.featured_image;
      // this.images = data.images;
      if (details.warrantyFeatures) {
        this.warranty_summary = details.warrantyFeatures.warranty_summary;
        this.hasWarranty();
      }

      if (data.variations.length > 0) {
        this.hasVariation();
      }

      // if (data.variations.length === 1) {
      //   this.color = data.variations[0].colors.id;
      //   this.size = data.variations[0].size.id;
      //   this.selling_price = data.variations[0].selling_price;
      //   this.weight = data.variations[0].weight;
      //   this.price = data.variations[0].price;
      //   this.type = data.variations[0].type;
      // }
    }

    if (!this.$store.state.categories.length) {
      this.$store.dispatch("getCategories");
    }
  },
};
</script>
