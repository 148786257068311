export default {
  methods: {
    filterCategories(arr, id, type, single = false) {
      const result = arr.filter((item) => item[type] === id);
      if (single) {
        return result[0];
      } else {
        return result;
      }
    },
  },
};
