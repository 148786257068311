<template>
  <div>
    <v-form ref="variationItemForm" v-model="valid">
      <v-row :class="isLastItem ? '' : 'mb-n8'">
        <v-col v-if="hasVariation('color')" cols="12" lg="3" md="6" sm="12">
          <v-autocomplete
            v-model="color"
            :items="colors"
            item-text="name"
            item-value="id"
            label="Color"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <v-col v-if="hasVariation('size')" cols="12" lg="3" md="6" sm="12">
          <v-autocomplete
            v-model="size"
            :items="sizes"
            item-text="code"
            item-value="id"
            label="Size"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <v-col v-if="hasVariation('weight')" cols="12" lg="3" md="6" sm="12">
          <v-text-field
            v-model="weight"
            label="Weight"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!-- <v-col v-if="hasVariation('type')" cols="12" lg="3" md="6" sm="12">
          <v-text-field
            v-model="type"
            label="Type"
            outlined
            dense
          ></v-text-field>
        </v-col> -->
        <!-- <v-col v-if="cakes" cols="12" lg="3" md="6" sm="12">
        <v-radio-group v-model="eggless" column dense class="mt-0">
          <template v-slot:label>
            <div>With OR Without Egg</div>
          </template>
          <v-radio
            label="Without Egg"
            color="red darken-3"
            :value="0"
          ></v-radio>
          <v-radio label="With Egg" color="red darken-3" :value="1"></v-radio>
        </v-radio-group>
      </v-col> -->
        <!-- <v-col v-if="cakes" cols="12" lg="3" md="6" sm="12">
        <v-radio-group v-model="sugarless" column dense class="mt-0">
          <template v-slot:label>
            <div>With OR Without Sugar</div>
          </template>
          <v-radio
            label="Without Sugar"
            color="red darken-3"
            :value="0"
          ></v-radio>
          <v-radio label="With Sugar" color="red darken-3" :value="1"></v-radio>
        </v-radio-group>
      </v-col> -->
        <v-col cols="12" lg="3" md="6" sm="12">
          <v-text-field
            v-model="stocks"
            label="Quantity *"
            outlined
            dense
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12">
          <v-text-field
            v-model="price"
            label="Price *"
            outlined
            dense
            :rules="[rules.required, rules.positiveInteger]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12">
          <v-text-field
            v-model="selling_price"
            label="Selling Price With Discount. Please enter the total discounted price"
            outlined
            dense
            hint="Please write the above price if you do not want to give discount on this product"
            :rules="[rules.required, rules.positiveInteger]"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="4">
        <v-file-input
          v-model="images"
          multiple
          label="Select Multiple Images "
          prepend-icon=""
          outlined
          dense
          small-chips
          @change="setImage($event)"
          accept="image/*"
        ></v-file-input>
      </v-col> -->
        <v-col cols="2" class="mt-1 d-flex">
          <v-btn
            small
            color="primary"
            :loading="loading"
            :disabled="!valid"
            @click="submitVariation"
            >Submit</v-btn
          >
          <v-btn
            v-if="addNext"
            small
            color="success"
            class="ml-4"
            v-show="isLastItem"
            @click="addAnother"
          >
            <v-icon small class="mr-2">mdi-plus-circle-outline</v-icon>
            Add</v-btn
          >
          <!-- <v-btn
          v-if="checkIfObject"
          small
          color="success"
          class="ml-4"
          v-show="isLastItem"
          @click="deleteVariation"
        >
          <v-icon small class="mr-2">mdi-plus-trash-can</v-icon></v-btn
        > -->
        </v-col>
      </v-row>
    </v-form>
    <v-divider class="mb-6"></v-divider>
  </div>
</template>
<script>
export default {
  props: {
    isLastItem: {
      type: Boolean,
      default: false,
    },
    cakes: {
      type: Boolean,
      default: false,
    },
    colors: {
      type: Array,
      default: () => [],
    },
    sizes: {
      type: Array,
      default: () => [],
    },
    variationson: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
    pid: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      loading: false,
      color: "",
      size: "",
      eggless: 0,
      sugarless: 0,
      weight: "",
      type: "",
      price: "",
      selling_price: "",
      valid: true,
      images: [],
      stocks: "",
      addNext: false,
      rules: {
        required: (v) => !!v || "This field is required.",
        positiveInteger: (v) => {
          if (v) return Number(v) > 0 || "Only positive number allowed";
          else return true;
        },
      },
    };
  },
  computed: {
    checkIfObject() {
      return typeof this.item !== "number";
    },
  },
  mounted() {
    this.color = this.item.colors ? this.item.colors.id : "";
    this.size = this.item.size ? this.item.size.id : "";
    this.weight = this.item.weight ? this.item.weight : "";
    this.type = this.item.type ? this.item.type : "";
    this.price = this.item.price;
    this.selling_price = this.item.selling_price
      ? this.item.selling_price
      : this.item.price;
    this.stocks = this.item.stocks;
    if (this.cakes) {
      this.eggless = this.item.eggless;
      this.sugarless = this.item.sugarless;
    }
    if (typeof this.item !== "number") {
      this.addNext = true;
    }
  },
  methods: {
    addAnother() {
      this.$emit("addOneMore");
    },
    setImage(files) {
      this.images = files;
    },
    async submitVariation() {
      if (this.$refs.variationItemForm.validate()) {
        this.loading = true;
        let formdata = new FormData();

        if (this.item) {
          formdata.append("vid", this.item.id);
          formdata.append("id", this.pid);
        }
        if (this.cakes) {
          formdata.append("eggless", 0);
          formdata.append("sugarless", 0);
        } else {
          formdata.append("eggless", 0);
          formdata.append("sugarless", 0);
        }
        formdata.append("stocks", this.stocks);
        formdata.append("size", this.size);
        formdata.append("color", this.color);
        formdata.append("type", this.type);
        formdata.append("weight", this.weight);
        this.images.forEach((file, index) => {
          formdata.append("images[" + index + "]", file);
        });
        formdata.append("price", this.price);
        formdata.append("selling_price", this.selling_price);
        formdata.append("is", "variation");

        await this.$store
          .dispatch("addProduct", formdata)
          .then(() => {
            this.$emit("multiVariationAdded", true);
            this.loading = false;
            this.addNext = true;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    hasVariation(type) {
      return this.variationson.filter((item) => item === type)[0];
    },
  },
};
</script>
<style lang="scss" scoped></style>
